<template>

    <div>
        <button :class="btnclass" v-html="link" @click.prevent="showSidebar" v-bind="$attrs"></button>
        <sidebar ref="sidebar" :title="title"><slot></slot></sidebar>
    </div>

</template>
<script>

    export default {
        name: 'ShowMoreComponent',
        props: {
            link: {
                type: String
            },
            title: {
                type: String,
                default: null
            },
            btnclass: {
                default: 'btn btn-link'
            }
        },
        methods: {
            showSidebar () {
                this.$refs.sidebar.show();
            },
        }
    }

</script>
