<template>

    <div>

        <button :class="btnclass" @click.prevent="showSidebar" v-bind="$attrs"><slot></slot></button>

        <sidebar
            ref="sidebar"
        >
            <div class="contact-form--form">

                <h2>{{ lang.contact.title }}</h2>

                <vue-form-generator :schema="schema" :model="contactModel" :options="formOptions" id="form-contact" @model-updated="modelUpdated"></vue-form-generator>

            </div>

        </sidebar>
    </div>

</template>
<script>

    import VueFormGenerator from "vue-form-generator"
    Vue.use(VueFormGenerator, {
        validators: {
            validatorName: (value, field, model) => {
                if (!value || value.length === 0)
                    return [lang.contact.name_error]
            },
            validatorEmail: (value, field, model) => {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
                if (!value || !re.test(value)) {
                    return [lang.contact.email_error]
                }
            },
            validatorPhone: (value, field, model) => {
                if (!value || value.length === 0)
                    return [lang.contact.phone_error]
            },
        }
    });

    export default {
        name: 'ContactComponent',
        data () {
            return {
                sending: false,
                lang: lang,

                contactModel: {
                    name: null,
                    email: null,
                    phone: null,
                    source: null,
                    other: null,
                    note: null,
                },
                schema: {
                    fields: [
                        {
                            type: 'input',
                            inputType: 'text',
                            label: lang.contact.name,
                            placeholder: lang.contact.name,
                            model: 'name',
                            required: true,
                            validator: ['validatorName']
                        },
                        {
                            type: 'input',
                            inputType: 'text',
                            label: lang.contact.email,
                            placeholder: lang.contact.email,
                            model: 'email',
                            required: true,
                            validator: ['validatorEmail']
                        },
                        {
                            type: 'input',
                            inputType: 'text',
                            label: lang.contact.phone,
                            placeholder: lang.contact.phone,
                            model: 'phone',
                            required: true,
                            validator: ['validatorPhone']
                        },
                        {
                            type: 'radios',
                            label: lang.contact.source,
                            model: 'source',
                            values: [
                                { name: lang.contact.sources.a, value: 'spletna stran' },
                                { name: lang.contact.sources.b, value: 'preko obstoječih investitorjev' },
                                { name: lang.contact.sources.c, value: 'na dogodku za ključne investitorje' },
                                { name: lang.contact.sources.d, value: 'drugo' },
                            ],
                            required: false
                        },
                        {
                            type: 'input',
                            inputType: 'text',
                            placeholder: lang.contact.sources_other,
                            model: 'other',
                            visible: function(model) {
                                return model && model.source === 'drugo';
                            }
                        },
                        {
                            type: 'textArea',
                            label: lang.contact.message,
                            placeholder: lang.contact.message,
                            model: 'note',
                            rows: 5,
                            required: false
                        },
                        {
                            type: 'submit',
                            buttonText: lang.contact.submit,
                            fieldClasses: 'btn btn-primary',
                            validateBeforeSubmit: true,
                            onSubmit: () => {
                                this.submitForm();
                            }
                        },

                    ]
                },
                formOptions: {
                    validateAfterLoad: false,
                    validateAfterChanged: true,
                    validateAsync: true,
                    fieldIdPrefix: 'contact-'
                }
            }
        },
        props: {
            btnclass: {
                default: 'btn btn-link'
            }
        },
        methods: {
            submitForm (e) {
                this.sending = true;
                axios({
                    url: '/api/contact',
                    method: 'post',
                    responseType: 'json',
                    data: querystring.stringify(this.contactModel)

                }).then(response => {
                    document.querySelector('#form-contact').innerHTML = `<p>${lang.contact.done_success}</p>`;
                }).catch(function (error) {
                    document.querySelector('#form-contact').innerHTML = `<p>${lang.contact.done_error}</p>`;
                });
                return false;
            },

            async showSidebar() {
                let self = this;
                this.$refs.sidebar.show();
                await this.$nextTick();
                Core.initForms('#form-contact');
            },

            modelUpdated(newVal, field) {
                // if (field === 'source' && newVal === 'other') {
                //     this.schema.fields.
                // }
            }
        }
    }
</script>
