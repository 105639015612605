class Components {

	constructor(com) {
		if (this[com.getAttribute('data-kr3-component')]) {
			this[com.getAttribute('data-kr3-component')](this, com);
		}
	}

	componentHome(com, el) {
		Promise.all([
			import(/* webpackChunkName: "components" */ 'aos'),
		]).then(([AOS]) => {

			window.addEventListener('scroll', function(event) {
				var depth, i, layer, layers, len, movement, topDistance, translate3d, opacity, fading;
				topDistance = this.pageYOffset;
				layers = document.querySelectorAll("[data-type='parallax']");
				for (i = 0, len = layers.length; i < len; i++) {
					let layer = layers[i],
						depth = layer.getAttribute('data-depth'),
						movement = -(topDistance * depth),
						opacity = layer.getAttribute('data-opacity'),
						opacityend = layer.getAttribute('data-opacity-end'),
						vish = (layer.getBoundingClientRect().top + (layer.offsetHeight * opacityend)) / (layer.offsetHeight * opacityend),
						fading = 1 - (1 - vish) * (1 - parseFloat(opacity)),
						translate3d = 'translate3d(0, ' + movement + 'px, 0)';

					// console.log(vish);

					layer.style['-ms-transform'] = translate3d;
					layer.style.transform = translate3d;
					layer.style.opacity = fading;
				}
			});

			AOS.init({
				// Global settings:
				disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
				startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
				initClassName: 'aos-init', // class applied after initialization
				animatedClassName: 'aos-animate', // class applied on animation
				useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
				disableMutationObserver: false, // disables automatic mutations' detections (advanced)
				debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
				throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

				// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
				offset: 0, // offset (in px) from the original trigger point
				delay: 100, // values from 0 to 3000, with step 50ms
				duration: 700, // values from 0 to 3000, with step 50ms
				easing: 'ease-out-quart', // default easing for AOS animations
				once: true, // whether animation should happen only once - while scrolling down
				mirror: false, // whether elements should animate out while scrolling past them
				anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
			});

			live('click', '.trigger-showmore', (e) => {
                window.scroll({
                    top: document.querySelector('.section--info').getBoundingClientRect().top + window.scrollY,
                    behavior: 'smooth',
                })
            });

		});

	}

}

Array.prototype.slice.call(document.querySelectorAll('*[data-kr3-component^="component"]')).forEach((value, index) => {
	new Components(value);
});
