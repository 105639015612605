<template>

    <div class="side-panel" @click="clickClose" :class="{'--is-visible': sidebarShown}">
        <div class="side-panel__container">
            <div class="side-panel__content">

                <div class="side-panel__header">
                    <h2 v-if="title">{{ title }}</h2>
                    <button class="side-panel__close" @click.prevent="close"><svg class="ticon"><use xlink:href="/images/icons.svg#icon-close"></use></svg></button>
                </div>

                <div class="side-panel__body">
                    <slot></slot>
                </div>

            </div>

            <div class="side-panel__bottom" v-if="picture">
                <img :src="picture">
            </div>
        </div>
    </div>

</template>
<script>

	export default {
		name: 'Sidebar',
		props: {
			title: {type: String},
            picture: {type: String},
		},
		data() {
			return {
                sidebarShown: false,
			}
		},
		methods: {
		    show () {
		        this.sidebarShown = true;
                document.querySelector('html').classList.add('overlay');
            },
			close () {
                this.sidebarShown = false;
                document.querySelector('html').classList.remove('overlay');
			},
            clickClose (e) {
                e.stopPropagation();
                if (e.target.classList.contains('side-panel')) {
                    this.close();
                }
            }
		},
        created () {
            let self = this;
            window.addEventListener('keydown', function (event) {
                if (self.sidebarShown && event.key === 'Escape') {
                    self.close();
                }
            });
        }
	}

</script>
