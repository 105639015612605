import Cookies from 'js-cookie';
import FloatLabels from 'float-labels.js';

// import jquery from 'jquery';

window.jQuery = jquery;
window.$ = jquery;
window.jquery = jquery;

let Core = {
	parsleyOptions: null,
    cookieDomain: null,

    init: () => {
        Core.config();
        Core.initForms();
        // Core.externalLinks();
        Core.initHelpers();
        Core.initCookies();
    },
	currenturl: () => {
		return (location.href.indexOf ('#') > -1) ? location.href.substring (0, location.href.indexOf ('#')) : location.href;
	},
	externalLinks: () => {
		let links = document.links;

		for (let i = 0, linksLength = links.length; i < linksLength; i++) {
			if (links[i].hostname !== window.location.hostname) {
				links[i].target = '_blank';
			}
		}
	},
    initForms: (target = 'form') => {
        let floatlabels = new FloatLabels( target, {
            customEvent      : null,
            customLabel      : null,
            customPlaceholder: null,
            exclude          : '.no-label',
            inputRegex       : /email|number|password|search|tel|text|url/,
            prefix           : 'fl-',
            prioritize       : 'label',
            requiredClass    : 'required',
            style            : 2,
            transform        : 'input, select, textarea',
        });
    },
	initHelpers: () => {
	    // Auto GA events from data-* attributes
	    live('click', '[data-gaevent]', function(e) {
            try {
                let eopt = {},
                    event_action = this.dataset.gaevent.length > 0 ? this.dataset.gaevent : 'Click';

                if (this.dataset.gaeventCategory) eopt.event_category = this.dataset.gaeventCategory;
                if (this.dataset.gaeventLabel) eopt.event_label = this.dataset.gaeventLabel;
                if (this.dataset.gaeventValue) eopt.value = this.dataset.gaeventValue;

                gtag('event', event_action, eopt);
            } catch (e) {}
        })

	},
    initCookies: () => {
        let cookieMeta = document.getElementById('cookiesmeta');
        if (cookieMeta) {
            Core.cookieDomain = cookiesmeta.getAttribute('data-cookiedomain');
        }
        let cookies_conf = {
            title: lang.cookies.cookies_window_title,
            subtitle: lang.cookies.cookies_window_lead,
            message: lang.cookies.cookies_window_text,
            delay: 0,
            expires: 60,
            acceptBtnLabel: lang.cookies.cookies_window_close,
            advancedBtnLabel: lang.cookies.cookies_window_more,
            cookieTypes: [
                {
                    type: lang.cookies.cookies_type_essential,
                    value: "essential",
                    checked: true,
                },
                {
                    type: lang.cookies.cookies_type_analytics,
                    value: "analytics",
                    checked: true,
                }
            ]
        };

        if (cookies_conf) {
            require('./components/gdpr-cookie');
            $.gdprcookie.init(cookies_conf);

            let cookiesSettingsSwitch = document.querySelector('.trigger-cookies-change');
            if (cookiesSettingsSwitch) {
                cookiesSettingsSwitch.addEventListener('click', function(e) {
                    e.preventDefault();
                    $.gdprcookie.display();
                });
            }
        }
    },
	config: () => {
		// Axios
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

        // CSRF token
        let token = document.head.querySelector('meta[name="csrf-token"]');
        if (token) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
        } else {
            console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
        }

        // Cookies
        Cookies.defaults.expires = 365;
		// Persley
		// Core.parsleyOptions = {
		// 	errorClass: 'is-invalid',
		// 	excluded: ':disabled,:hidden,:hidden:not(.hideinput)',
		// 	errorsWrapper: '<div class="invalid-feedback"></div>',
		// 	errorTemplate: '<span></span>'
		// };

	},
    initScrollTracker: () => {
        require('./components/scroll-tracker');

        var tracker = window.ScrollTracker();
        tracker.on({
            percentages: {
                every: [25]
            }
        }, function(evt) {
            try {
                gtag('event', 'Scroll Depth', {
                    'event_category': 'Scroll Depth',
                    'event_action': 'Percentage',
                    'event_label': evt.data.label,
                    'non_interaction': true
                });
            } catch (e) {}
        });
    }
}
// Core.init();
global.Core = Core;

// Live event binding
global.live = function live (eventType, elementQuerySelector, cb) {
	document.addEventListener(eventType, function (event) {
		var qs = document.querySelectorAll(elementQuerySelector);
		if (qs) {
			var el = event.target, index = -1;
			while (el && ((index = Array.prototype.indexOf.call(qs, el)) === -1)) {
				el = el.parentElement;
			}
			if (index > -1) {
				cb.call(el, event);
			}
		}
	});
}

/**
 * @see https://dom.spec.whatwg.org/#interface-element
 * @see https://developer.mozilla.org/docs/Web/API/Element/matches#Polyfill
 * @see https://gist.github.com/jonathantneal/3062955
 * @see https://github.com/jonathantneal/closest
 *
 * From: https://gist.github.com/sounisi5011/f6f4b915fa8ab68ed3df
 */
!function(a){var c;if(a=a.Element)a=a.prototype,!(c=a.matches)&&(c=a.matchesSelector||a.mozMatchesSelector||a.msMatchesSelector||a.oMatchesSelector||a.webkitMatchesSelector||a.querySelectorAll&&function matches(a){a=(this.parentNode||this.document||this.ownerDocument).querySelectorAll(a);for(var b=a.length;0<=--b&&a.item(b)!==this;);return-1<b})&&(a.matches=c),!a.closest&&c&&(a.closest=function closest(a){for(var b=this;b;){if(1===b.nodeType&&b.matches(a))return b;b=b.parentNode}return null})}(Function("return this")());

