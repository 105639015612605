import AccordionItem from './AccordionItem';
import Accordion from './Accordion';

export default {
    install(Vue) {
        Vue.component('accordion-item', AccordionItem);
        Vue.component('accordion', Accordion);
    },
};

export { AccordionItem, Accordion };
