<template>

    <div class="handorgel" ref="accordion">
        <slot></slot>
    </div>

</template>
<script>

    export default {
        name: 'Accordion',
        data () {
            return {
                instance: null
                // sending: false
            }
        },
        methods: {
            init () {
                let handorgel = require('handorgel');
                this.instance = new handorgel.default(this.$refs.accordion);
            }
        },
        mounted() {
            this.init()
        }
    }

</script>
