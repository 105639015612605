window.Vue = require('vue');
Vue.config.productionTip = false;

// import 'bootstrap/js/src/collapse'

import './core';
import './components';

// Lang
import languageBundle from '@kirschbaum-development/laravel-translations-loader/php!@kirschbaum-development/laravel-translations-loader';
global.lang = languageBundle[document.querySelector('html').getAttribute('lang')];

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

import VueFeather from 'vue-feather'
Vue.component('vue-feather', VueFeather)

Vue.component('sidebar', require('./components/Sidebar.vue').default);
Vue.component('show-more', require('./components/ShowMore.vue').default);
Vue.component('contact-us', require('./components/Contact.vue').default);

import Accordion from './components/accordion'
Vue.use(Accordion)

// Collapse plugin
import VueCollapse from 'vue2-collapse';
Vue.use(VueCollapse);

import VueTippy from 'vue-tippy'
Vue.use(VueTippy,{
    flipDuration: 0,
    interactive: true,
    theme: 'light',
    arrow: true,
    arrowType: 'round',
    delay: 50,
    duration: 200,
    distance: 20,
    placement: 'bottom',
    animation: 'shift-toward',
    trigger: 'click',
})

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});

//  Init core stuff
Core.init();

// IE SVG fix
if(/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
    require('svgxuse');
}
