<template>

    <fragment>
        <h3 class="handorgel__header">
            <button class="handorgel__header__button">
                <div v-html="title"></div>
                <vue-feather type="chevron-right"></vue-feather>
            </button>
        </h3>
        <div class="handorgel__content">
            <div class="handorgel__content__inner">
                <slot></slot>
            </div>
        </div>
    </fragment>

</template>
<script>


    export default {
        name: 'AccordionItem',
        props: {
            title: {
                type: String,
            }
        }
    }

</script>
